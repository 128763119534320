<template>
  <div class="page">
    <Navbar title="职业证书" :callback="back" type="SMP" />
    <div class="list">
      <van-row class="title">
        <van-col span="4">时间</van-col>
        <van-col span="14">名称</van-col>
        <van-col span="6">操作</van-col>
      </van-row>
      <van-row class="record" v-for="cert in certList" :key="cert.id">
        <van-col span="4">{{ cert.issueDate }}</van-col>
        <van-col span="14">{{ cert.name }}</van-col>
        <van-col span="6"
          ><van-tag :color="COLOR" plain @click="updateCert(cert.seq)"
            >修改</van-tag
          >&nbsp;
          <van-tag type="danger" plain @click="deleteCert(cert.seq)"
            >删除</van-tag
          ></van-col
        >
      </van-row>
      <van-row class="no-record" v-if="certList.length <= 0">
        <van-col span="24">
          <van-image
            width="103"
            height="103"
            :src="require('../../../../assets/images/home/no-record.png')"
          ></van-image
        ></van-col>
      </van-row>
    </div>
    <van-row class="btn">
      <van-col span="24">
        <van-button
          :color="COLOR"
          icon="plus"
          size="small"
          @click="createCert()"
          >新增证书</van-button
        >
      </van-col>
    </van-row>
  </div>
</template>
<script>
import { Icon, Tag, Image } from 'vant'
import Navbar from '../../common/Navbar.vue'
export default {
  components: {
    Navbar: Navbar,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Image.name]: Image
  },
  data () {
    return {
      personCode: '',
      certList: []
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.retrieveCertList()
  },
  methods: {
    async retrieveCertList () {
      var pd = { personCode: this.personCode, group: 'CRT', type: 'DTL' }
      this.certList = []
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var content = JSON.parse(element.content)
          var cert = { seq: element.seq, name: content.name, number: content.number, issueDate: content.issueDate }
          this.certList.push(cert)
        })
      }
    },
    createCert () {
      this.$router.push({ path: '/mde/people/person/dtlCertEdit', query: { personCode: this.personCode, seq: this.certList.length + 1 } })
    },
    updateCert (seq) {
      this.$router.push({ path: '/mde/people/person/dtlCertEdit', query: { personCode: this.personCode, seq: seq } })
    },
    deleteCert (seq) {
      this.$dialog.confirm({
        title: '删除确认',
        message: '确认删除证书吗?'
      })
        .then(() => {
          this.deleteCertExecute(seq)
        })
    },
    async deleteCertExecute (seq) {
      var pd = { key: 'PSN_CRT_ITEM', personCode: this.personCode, type: 'DTL', seq: seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/deletePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.retrieveCertList()
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '删除失败:' + res.msg
        })
      }
    },
    back () {
      var url = window.sessionStorage.getItem(this.SESSION_BACKURL)
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.list {
  margin: 5px;
  .title {
    font-size: 13px;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }
  .record {
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #eee;
  }
  .no-record {
    margin: 30px auto;
  }
}
.btn {
  margin-top: 30px;
}
</style>
